<template>
    <div class="custom-select" :tabindex="tabindex" @blur="open = false">
        <div class="selected" :class="{ open: open }" @click="open = !open">
            {{ selected }}
        </div>
        <div class="items" :class="{ selectHide: !open }">
            <div
                v-for="(option, i) of options"
                :key="i"
                @click="select(i)"
            >
                {{ option }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true,
        },
        default: {
            type: String,
            required: false,
            default: null,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            selected: this.default ? this.default : this.options.length > 0 ? this.options[0] : null,
            open: false,
        };
    },
    mounted() {
        this.$emit("input", this.selected);
    },
    methods: {
        select(i) {
            this.selected = this.options[i];
            this.open = false;
            this.$emit('input', this.selected);
        }
    }
};
</script>

<style scoped>
.custom-select {
    position: relative;
    width: 100%;
    outline: none;
    line-height: 56px;
    height: 56px;
    border-radius: 15px;
    box-sizing: border-box;
}

.custom-select .selected {
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid #FFC900;
    color: #1A2744;
    padding-left: 20px;
    cursor: pointer;
    user-select: none;
}

.custom-select .selected.open {
    border: 1px solid #FFC900;
    border-radius: 15px 15px 0px 0px;
    background-color: #FFC900;
    /* color: #fff; */
}

.custom-select .selected:after {
    position: absolute;
    content: "";
    top: 18px;
    right: 18px;
    width: 11.38px;
    height: 20px;
    background: url(../assets/images/select.svg) no-repeat center;
    background-size: contain;
}

.custom-select .selected.open:after {
    background: url(../assets/images/select-open.svg) no-repeat center;
}

.custom-select .selected::before {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    right: 50px;
    width: 1px;
    height: 40px;
    background: #F5F5F5;
    @media screen and (max-width: 950px) {
        height: 20px;
    }
}

.custom-select .selected.open::before {
    display: none;
}

.custom-select .items {
    color: #fff;
    border-radius: 0px 0px 15px 15px;
    overflow: hidden;
    border-right: 1px solid #FFC900;
    border-left: 1px solid #FFC900;
    border-bottom: 1px solid #FFC900;
    position: absolute;
    background-color: #FFC900;
    left: 0;
    right: 0;
    z-index: 1;
}

.custom-select .items div {
    color: #fff;
    padding-left: 20px;
    cursor: pointer;
    user-select: none;
}

.custom-select .items div:hover {
    background-color: #fff;
    color: #1A2744;
}

.selectHide {
    display: none;
}



@media screen and (max-width: 950px) {
     .custom-select .selected::before {
        height: 35px;
     }
}
</style>
